import { useTherapistApi } from '@/composables/therapistApi';
import { useIFetch } from '@/composables/useIFetch';
import apiRoutes from '@/const/apiRoutes';
export const useTimeZoneStore = defineStore('timezone', () => {
   const { setSnackbarDetail } = useSnackbarStore();
   const { therapistInfo } = storeToRefs(useTherapistInfoStore());
   const { getTherapistInfo } = useTherapistInfoStore();
   const { isClient, clientProfile } = storeToRefs(useClientInfo());
   const { getClientInfo } = useClientInfo();

   const currentClientTimezone = computed(() =>
      isClient.value ?
         clientProfile.value?.timezone
      :  therapistInfo.value?.timezone
   );
   const updateTimeZone = async (
      timezone: string,
      useClient = false,
      noRefresh = false
   ) => {
      if (isClient.value || useClient) {
         const { data, error } = await updateTimeZoneClient(timezone);
         if (data.value && !noRefresh) window.location.reload();
         return { data, error };
      } else {
         const { data, error } = await updateTimeZoneTherapist(timezone);
         if (data.value && !noRefresh) window.location.reload();
         return { data, error };
      }
   };
   const updateTimeZoneClient = async (timezone: string) => {
      const { data, error } = await useIFetch(
         apiRoutes.client.profile.timezone,
         {
            method: 'PUT',
            body: {
               timezone: timezone,
            },
         }
      );
      if (error.value)
         setSnackbarDetail({
            show: true,
            title: error.value?.message,
            type: 'error',
         });
      return { data, error };
   };
   const updateTimeZoneTherapist = async (timezone: string) => {
      const { data, error } = await useTherapistApi(
         apiRoutes.therapist.profile.timezone,
         {
            method: 'PUT',
            body: {
               timezone: timezone,
            },
         }
      );

      if (error.value)
         setSnackbarDetail({
            show: true,
            title: error.value?.message,
            type: 'error',
         });
      return { data, error };
   };

   return {
      currentClientTimezone,
      updateTimeZone,
   };
});
