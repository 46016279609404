<script setup lang="ts">
   import { computed } from 'vue';
   import type { RouteLocationRaw } from 'vue-router';

   const props = defineProps({
      loading: Boolean,
      disabled: Boolean,
      variant: {
         type: String, // 'default' | 'outlined' | 'plain'
         default: 'default',
      },
      color: {
         type: String,
         default: 'primary-500',
      },
      borderColor: {
         type: String,
         default: '',
      },
      href: {
         type: String,
         required: false,
      },
      block: {
         type: Boolean,
      },
      size: {
         type: String, // 'sm' | 'default' | 'lg'
         default: 'default',
      },
      prependIcon: Function,
      appendIcon: Function,
      iconClasses: {
         type: String,
         default: '',
      },
      to: {
         type: [String, Object] as PropType<RouteLocationRaw>,
         required: false,
      },
      loadingColor: {
         type: String,
         default: 'white',
      },
   });

   const variantClasses = computed(() => {
      const borderClass =
         props.borderColor ?
            `border-${props.borderColor}`
         :  `border-${props.color}`;

      switch (props.variant) {
         case 'outlined':
            return `border ${borderClass} text-${props.color}`;
         case 'plain':
            return `text-${props.color}`;
         default:
            return `bg-${props.color} text-white hover:bg-opacity-90`;
      }
   });

   const sizeClasses = computed(() => {
      switch (props.size) {
         case 'sm':
            return 'px-3 py-2 text-xs';
         case 'lg':
            return 'px-7 py-4 text-lg';
         default:
            return 'px-4 py-2 text-sm';
      }
   });

   const iconSizeClasses = computed(() => {
      switch (props.size) {
         case 'sm':
            return 'w-4 h-4';
         case 'lg':
            return 'w-6 h-6';
         default:
            return 'w-4 h-4';
      }
   });
</script>

<template>
   <template v-if="to || href">
      <NuxtLink
         :href="disabled || loading ? '' : to || href"
         v-bind="$attrs"
         :class="[
            'rounded flex items-center justify-center cursor-pointer',
            variantClasses,
            sizeClasses,
            { 'w-full': props.block },
            { 'opacity-50 cursor-not-allowed': disabled || loading },
         ]"
         :aria-disabled="disabled || loading"
      >
         <component
            v-if="prependIcon && !loading"
            :is="prependIcon"
            :class="[iconSizeClasses, `${$slots.default ? 'me-2' : ''}`]"
         />
         <span v-if="props.loading" class="mr-2">
            <BaseLoading :color="`${loadingColor}`" />
         </span>
         <slot v-else></slot>
         <component
            v-if="appendIcon && !loading"
            :is="appendIcon"
            :class="[iconSizeClasses, 'ms-2', iconClasses]"
         />
      </NuxtLink>
   </template>
   <button
      v-else
      v-bind="$attrs"
      :class="[
         'rounded flex items-center justify-center cursor-pointer',
         variantClasses,
         sizeClasses,
         { 'w-full': props.block },
         { 'opacity-50 cursor-not-allowed': disabled || loading },
      ]"
      :disabled="disabled || loading"
   >
      <component
         v-if="prependIcon && !loading"
         :is="prependIcon"
         :class="[iconSizeClasses, `${$slots.default ? 'me-2' : ''}`]"
      />
      <span v-if="props.loading" class="mr-2">
         <BaseLoading :color="`${loadingColor}`" size="w-6 h-6" />
      </span>
      <slot v-else></slot>
      <component
         v-if="appendIcon && !loading"
         :is="appendIcon"
         :class="[iconSizeClasses, 'ms-2', iconClasses]"
      />
   </button>
</template>
