export const useAnonymousClientStorage = () => {
   const anonymousId = useCookie<string | null>('anonymousId');

   const storAnonymous = (newAnonymous: string) => {
      anonymousId.value = newAnonymous;
   };

   const clearAnonymous = () => {
      anonymousId.value = null;
   };
   return { storAnonymous, clearAnonymous, anonymousId };
};
