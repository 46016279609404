import * as Sentry from '@sentry/vue';
const useSentry = () => {
   const setUser = (
      user: {
         id: string;
         email: string;
      } | null
   ) => {
      Sentry.setUser(user);
   };
   return { setUser };
};
export default useSentry;
