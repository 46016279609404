import revive_payload_client_4sVQNw7RlN from "/home/runner/work/hub/hub/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/hub/hub/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/hub/hub/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/hub/hub/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/hub/hub/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/hub/hub/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/hub/hub/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/hub/hub/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/hub/hub/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/hub/hub/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/home/runner/work/hub/hub/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_client_RZVNejKJBe from "/home/runner/work/hub/hub/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/home/runner/work/hub/hub/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/home/runner/work/hub/hub/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import clarity_client_hudlDOj6WH from "/home/runner/work/hub/hub/plugins/clarity.client.js";
import sentry_3AyO8nEfhE from "/home/runner/work/hub/hub/plugins/sentry.ts";
import timezone_c8yjmg1TIw from "/home/runner/work/hub/hub/plugins/timezone.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  plugin_client_RZVNejKJBe,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  clarity_client_hudlDOj6WH,
  sentry_3AyO8nEfhE,
  timezone_c8yjmg1TIw
]