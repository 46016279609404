<template>
   <!-- Global notification live region, render this permanently at the end of the document -->
   <div
      aria-live="assertive"
      class="pointer-events-none fixed z-50 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
   >
      <div
         class="flex w-full flex-col z-50 items-center space-y-4 sm:items-end"
      >
         <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
         <transition
            enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
         >
            <div
               v-if="show"
               :class="
                  snackbarDetail?.type === 'message' ?
                     'bg-secondary-700 '
                  :  'bg-error-600'
               "
               class="pointer-events-auto border z-100 w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-primary-600 ring-opacity-5"
            >
               <div class="p-4">
                  <div class="flex items-start">
                     <div class="flex-shrink-0">
                        <CheckCircleIcon
                           v-if="snackbarDetail?.type === 'message'"
                           class="h-6 w-6 text-white"
                           aria-hidden="true"
                        />
                        <ExclamationTriangleIcon
                           v-else
                           class="h-6 w-6 text-white"
                        ></ExclamationTriangleIcon>
                     </div>
                     <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p class="text-sm font-medium text-white">
                           {{ snackbarDetail?.title }}
                        </p>
                        <p
                           v-if="snackbarDetail?.description"
                           class="mt-1 text-sm text-white"
                        >
                           {{ snackbarDetail?.description }}
                        </p>
                     </div>
                     <div class="ml-4 flex flex-shrink-0">
                        <button
                           type="button"
                           @click="updateSnackbar"
                           class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                           <span class="sr-only">Close</span>
                           <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </transition>
      </div>
   </div>
</template>

<script>
   import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/20/solid';
   import { CheckCircleIcon } from '@heroicons/vue/24/outline';
   import { mapActions, mapState } from 'pinia';

   export default {
      components: { CheckCircleIcon, ExclamationTriangleIcon, XMarkIcon },
      data() {
         return {
            show: false,
         };
      },
      watch: {
         'snackbarDetail.show'() {
            this.show = true;
            setTimeout(() => {
               this.show = false;
               this.updateSnackbar();
            }, 1000);
         },
      },
      computed: {
         ...mapState(useSnackbarStore, ['snackbarDetail']),
      },

      methods: {
         ...mapActions(useSnackbarStore, ['setSnackbarDetail']),
         updateSnackbar() {
            this.setSnackbarDetail({ ...this.snackbarDetail, show: false });
         },
      },
   };
</script>
