export type RegisterStep = {
   to: { name: string };
   required: string[];
   enableSignUp: boolean;
};

export enum SponsorType {
   Company = 'company',
   Self = 'self',
   Insurance = 'insurance',
}

export enum TherapyType {
   Teen = 'Teen',
   Couples = 'Couples',
   Individual = 'Individual',
}
export const useRegisterSteps = () => {
   const teenRegisterSteps: ComputedRef<RegisterStep[]> = computed(() => {
      const therapyType =
         useClientAuthStore().anonymousData?.therapy_type?.name ||
         useClientInfo()?.clientProfile?.therapy_type?.name;
      if (therapyType && therapyType == TherapyType.Teen) {
         return [
            {
               to: { name: 'auth-client-register-parent-verified' },
               required: ['therapy_reasons'],
               enableSignUp: false,
            },
         ];
      } else {
         return [
            {
               to: { name: 'auth-client-register-age-groups' },
               required: ['therapy_reasons'],
               enableSignUp: false,
            },
            {
               to: { name: 'auth-client-register-client-gender' },
               required: ['age_group'],
               enableSignUp: false,
            },
         ];
      }
   });
   const outOfPocketCoverageSteps: ComputedRef<RegisterStep[]> = computed(
      () => {
         return [
            {
               to: { name: 'auth-client-register-coverage' },
               required: [],
               enableSignUp: false,
            },

            {
               to: { name: 'auth-client-register-therapy-type' },
               required: ['sponsor'],
               enableSignUp: false,
            },
            {
               to: { name: 'auth-client-register-therapy-reasons' },
               required: ['therapy_type'],
               enableSignUp: false,
            },
            ...teenRegisterSteps.value,
            {
               to: { name: 'auth-client-register-therapist-preference' },
               required: ['gender'],
               enableSignUp: true,
            },
         ];
      }
   );
   const companyCoverageSteps: ComputedRef<RegisterStep[]> = computed(() => {
      return [
         {
            to: { name: 'auth-client-register-coverage' },
            required: [],
            enableSignUp: false,
         },
         {
            to: { name: 'auth-client-register-confirmedCompanyBenefit' },
            required: ['email', 'sponsor', 'benefit_code'],
            enableSignUp: false,
         },
         {
            to: { name: 'auth-client-register-therapy-type' },
            required: ['sponsor', 'benefit_code'],
            enableSignUp: false,
         },
         {
            to: { name: 'auth-client-register-therapy-reasons' },
            required: ['therapy_type'],
            enableSignUp: false,
         },
         ...teenRegisterSteps.value,
         {
            to: { name: 'auth-client-register-therapist-preference' },
            required: ['gender'],
            enableSignUp: true,
         },
      ];
   });
   const insuranceCoverageSteps: ComputedRef<RegisterStep[]> = computed(() => {
      return [
         {
            to: { name: 'auth-client-register-coverage' },
            required: [],
            enableSignUp: true,
         },
         {
            to: {
               name: 'auth-client-register-confirmedInsuranceBenefitCode',
            },
            required: ['sponsor'],
            enableSignUp: false,
         },
         {
            to: { name: 'auth-client-register-therapy-type' },
            required: ['sponsor'],
            enableSignUp: false,
         },
         {
            to: { name: 'auth-client-register-therapy-reasons' },
            required: ['therapy_type'],
            enableSignUp: false,
         },
         ...teenRegisterSteps.value,
         {
            to: { name: 'auth-client-register-therapist-preference' },
            required: ['gender'],
            enableSignUp: true,
         },
      ];
   });

   const currentRegisterStep: ComputedRef<RegisterStep[]> = computed(() => {
      const sponsor =
         useClientAuthStore().anonymousData?.sponsor ||
         useClientInfo()?.clientProfile?.sponsor;

      if (!sponsor) return outOfPocketCoverageSteps.value;
      switch (sponsor) {
         case SponsorType.Insurance:
            return insuranceCoverageSteps.value;
         case SponsorType.Company:
            return companyCoverageSteps.value;
         default:
            return outOfPocketCoverageSteps.value;
      }
   });
   const switchStepBasedByData = () => {
      const data =
         useClientInfo()?.clientProfile || useClientAuthStore().anonymousData;
      if (!data) return null;
      const steps = currentRegisterStep?.value;
      if (steps.length === 0) return null;

      if (data.sponsor == SponsorType.Insurance && !data.therapy_type) {
         return {
            to: {
               name: 'auth-client-register-confirmedInsuranceBenefitCode',
            },
            required: ['sponsor'],
            enableSignUp: false,
         };
      }

      for (let i = 0; i < steps.length; i++) {
         const step = steps[i];
         const hasUnfilledField = step.required.some((field) => {
            if (Array.isArray(data?.[field])) {
               return data[field].length === 0;
            }
            return !data[field];
         });
         if (hasUnfilledField) {
            return i > 0 ? steps[i - 1] : steps[0];
         }
      }
      return null;
   };
   return {
      outOfPocketCoverageSteps,
      companyCoverageSteps,
      insuranceCoverageSteps,
      switchStepBasedByData,
      currentRegisterStep,
   };
};
