import type { IToken } from './useAuthClientStorage';

export const useAuthTherapistStorage = () => {
   const therapistToken = useCookie<IToken | null>('therapistToken');

   const isTherapistAuthenticated = computed(
      () => therapistToken.value?.access_token
   );

   const store = (newToken: IToken) => {
      therapistToken.value = newToken;
   };

   const clear = () => {
      therapistToken.value = null;
   };
   return { store, clear, therapistToken, isTherapistAuthenticated };
};
