import apiRoutes from '@/const/apiRoutes';
import type {
   Education,
   License,
   TherapistInformation,
   TherapistProfile,
} from '@/types/therapistInformation';

export const useTherapistInfoStore = defineStore('therapistInfo', () => {
   const { setUser } = useSentry();

   const { setSnackbarDetail } = useSnackbarStore();
   const therapistInfo: Ref<TherapistInformation | null> = ref(null);
   const therapistProfile: Ref<TherapistProfile | null> = ref(null);
   const therapistImage = ref(therapistInfo?.value?.image);
   const reloadList = ref(null);
   const needToChangeTimeZone = ref(false);
   const setReloadValue = (payload) => {
      reloadList.value = payload || null;
   };
   const getTherapistInfo = async () => {
      const { data, error } =
         await useTherapistApi<TherapistInformation | null>(
            apiRoutes?.therapist?.profile?.get
         );
      therapistInfo.value = data.value;
      if (data.value) {
         const detectedTimeZone =
            Intl.DateTimeFormat().resolvedOptions().timeZone;
         needToChangeTimeZone.value = data?.value?.timezone != detectedTimeZone;
         setUser({
            id: data?.value?.id,
            email: data?.value?.email,
         });
      }
      if (error.value) {
         setSnackbarDetail({
            show: true,
            title: 'Something went wrong',
            type: 'error',
         });
      }
   };
   const updateTherapistImage = (file_id: string) => {
      if (therapistInfo.value) therapistInfo.value.image = file_id;
      therapistImage.value = file_id;
   };
   const getTherapistProfile = async () => {
      const { data, error } = await useTherapistApi<TherapistProfile | null>(
         apiRoutes?.therapist?.profile?.info
      );
      therapistProfile.value = data.value;
      therapistImage.value = data?.value?.image;
      if (error.value) {
         setSnackbarDetail({
            show: true,
            title: 'Something went wrong',
            type: 'error',
         });
      }
   };
   const therapistLicenses: Ref<License[]> = ref([]);
   const therapistEducation: Ref<Education[]> = ref([]);
   const getTherapistEducationLicenses = async () => {
      let { data, error } = await useTherapistApi<{
         educations: Education[];
         licenses: License[];
      }>(apiRoutes.therapist.profile.educationLicense);
      if (data?.value) {
         therapistEducation.value = data.value.educations;
         therapistLicenses.value = data.value.licenses;
      }
      if (error?.value)
         setSnackbarDetail({
            type: 'error',
            message: error?.value?.data?.message,
         });
   };

   const updateTherapistInfo = async (formData: any) => {
      const { error, data } = await useTherapistApi(
         apiRoutes.therapist.profile.update,
         {
            body: formData,
            method: 'PUT',
         }
      );
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error?.value?.data?.message,
            type: 'error',
         });
      }
      if (data.value) {
         setSnackbarDetail({
            type: 'message',
            show: true,
            title: 'Successfully updated profile.',
         });
         await getTherapistInfo();
      }
      return { data, error };
   };

   const updateEducationAndLiscence = async (formData: {
      type: 'education' | 'license';
      action: 'update' | 'add' | 'delete';

      language_id?: string;

      id?: string;

      name?: string;
      institution?: string;
      graduation_year?: number;

      authority?: string;
      date?: string;
      expire?: string;

      arabicLanguage: { name: string; id: string };
   }) => {
      const { arabicLanguage, ...rest } = formData;
      const { data, error } = await useTherapistApi<{ id: string }>(
         apiRoutes.therapist.profile.updateEducationLicense,
         {
            method: 'PUT',
            body: rest,
         }
      );
      if (data.value) {
         switch (formData.action) {
            case 'add':
               setSnackbarDetail({
                  title: `Successfully added ${formData.type}.`,
                  type: 'message',
               });
               if (formData.type === 'education') {
                  therapistEducation.value.push({
                     id: data.value.id,
                     graduation_year: formData.graduation_year || 0,
                     institution: formData.institution || '',
                     name: formData.name || '',
                     translations: [
                        {
                           name: formData.name || '',
                           institution: formData.institution || '',
                           language: formData.arabicLanguage,
                        },
                     ],
                  });
               } else {
                  therapistLicenses.value.push({
                     id: data.value.id,
                     authority: formData.authority || '',
                     date: formData.date || '',
                     expire: formData.expire || '',
                     translations: [
                        {
                           authority: formData.authority || '',
                           language: formData.arabicLanguage,
                        },
                     ],
                  });
               }
               break;
            case 'update':
               setSnackbarDetail({
                  title: `Successfully updated ${formData.type}.`,
                  type: 'message',
               });
               if (formData.type === 'education')
                  therapistEducation.value = therapistEducation.value.map(
                     (item) =>
                        item.id === formData.id ?
                           {
                              ...item,
                              graduation_year:
                                 formData.graduation_year ||
                                 item.graduation_year,
                              institution:
                                 formData.institution || item.institution,
                              name: formData.name || item.name,
                              translations:
                                 formData.language_id ?
                                    item.translations?.map((translation) =>
                                       (
                                          translation.language.id ==
                                          formData.language_id
                                       ) ?
                                          {
                                             ...translation,
                                             name:
                                                formData.name ||
                                                translation.name,
                                             institution:
                                                formData.institution ||
                                                translation.institution,
                                          }
                                       :  translation
                                    ) || []
                                 :  item.translations,
                           }
                        :  item
                  );
               else
                  therapistLicenses.value = therapistLicenses.value.map(
                     (item) =>
                        item.id === formData.id ?
                           {
                              ...item,
                              authority: formData.authority || item.authority,
                              date: formData.date || item.date,
                              expire: formData.expire || item.expire,
                              translations:
                                 formData.language_id ?
                                    item.translations?.map((translation) =>
                                       (
                                          translation.language.id ==
                                          formData.language_id
                                       ) ?
                                          {
                                             ...translation,
                                             authority:
                                                formData.authority ||
                                                translation.authority,
                                          }
                                       :  translation
                                    ) || []
                                 :  item.translations,
                           }
                        :  item
                  );
               break;
            case 'delete':
               setSnackbarDetail({
                  title: `Successfully deleted ${formData.type}.`,
                  type: 'message',
               });
               if (formData.type === 'education')
                  therapistEducation.value = therapistEducation.value.filter(
                     (item) => item.id !== formData.id
                  );
               else
                  therapistLicenses.value = therapistLicenses.value.filter(
                     (item) => item.id !== formData.id
                  );
               break;
         }
      }
      if (error?.value)
         setSnackbarDetail({
            title: error?.value?.data?.message,
            type: 'error',
         });
      return { data, error };
   };
   return {
      therapistLicenses,
      therapistEducation,
      getTherapistEducationLicenses,
      getTherapistInfo,
      therapistInfo,
      therapistImage,
      updateTherapistImage,
      getTherapistProfile,
      therapistProfile,
      updateEducationAndLiscence,
      updateTherapistInfo,
      reloadList,
      setReloadValue,
      needToChangeTimeZone,
   };
});
