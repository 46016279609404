import { default as forget_45passwordff6qnW44XHMeta } from "/home/runner/work/hub/hub/pages/auth/client/forget-password.vue?macro=true";
import { default as loginggWSM3H3jZMeta } from "/home/runner/work/hub/hub/pages/auth/client/login.vue?macro=true";
import { default as age_45groupskso8Ky8zd6Meta } from "/home/runner/work/hub/hub/pages/auth/client/register/age-groups.vue?macro=true";
import { default as client_45gender1ykDEi4QTfMeta } from "/home/runner/work/hub/hub/pages/auth/client/register/client-gender.vue?macro=true";
import { default as confirmedCompanyBenefitxHUj9fTHP3Meta } from "/home/runner/work/hub/hub/pages/auth/client/register/confirmedCompanyBenefit.vue?macro=true";
import { default as confirmedInsuranceBenefitCodeKRbUjiyju7Meta } from "/home/runner/work/hub/hub/pages/auth/client/register/confirmedInsuranceBenefitCode.vue?macro=true";
import { default as coveragewMAxJqpXZAMeta } from "/home/runner/work/hub/hub/pages/auth/client/register/coverage.vue?macro=true";
import { default as parent_45verifiedqn7xgixzNmMeta } from "/home/runner/work/hub/hub/pages/auth/client/register/parent-verified.vue?macro=true";
import { default as risk_45assessment64usT9rGT7Meta } from "/home/runner/work/hub/hub/pages/auth/client/register/risk-assessment.vue?macro=true";
import { default as therapist_45preference0XEyXyabrsMeta } from "/home/runner/work/hub/hub/pages/auth/client/register/therapist-preference.vue?macro=true";
import { default as therapy_45reasonsSPNaPaxssFMeta } from "/home/runner/work/hub/hub/pages/auth/client/register/therapy-reasons.vue?macro=true";
import { default as therapy_45typeodAKFpb7axMeta } from "/home/runner/work/hub/hub/pages/auth/client/register/therapy-type.vue?macro=true";
import { default as verify_45emailyor7wEzJ0cMeta } from "/home/runner/work/hub/hub/pages/auth/client/register/verify-email.vue?macro=true";
import { default as signupPyz1F1js3QMeta } from "/home/runner/work/hub/hub/pages/auth/client/signup.vue?macro=true";
import { default as indexoq4ZR0Rdu5Meta } from "/home/runner/work/hub/hub/pages/auth/index.vue?macro=true";
import { default as _91token_93Fbd7TWsa1gMeta } from "/home/runner/work/hub/hub/pages/auth/therapist/activate/[token].vue?macro=true";
import { default as forget_45passwordCJzlNXwUlEMeta } from "/home/runner/work/hub/hub/pages/auth/therapist/forget-password.vue?macro=true";
import { default as loginXWucLVgXm4Meta } from "/home/runner/work/hub/hub/pages/auth/therapist/login.vue?macro=true";
import { default as help_45deskdXMtZJXDM7Meta } from "/home/runner/work/hub/hub/pages/client/account/help-desk.vue?macro=true";
import { default as overviewxkwK6d3COxMeta } from "/home/runner/work/hub/hub/pages/client/account/overview.vue?macro=true";
import { default as coveragesip7dMY8mF8Meta } from "/home/runner/work/hub/hub/pages/client/account/plan/coverages.vue?macro=true";
import { default as overviewyhbUS6gBg0Meta } from "/home/runner/work/hub/hub/pages/client/account/plan/overview.vue?macro=true";
import { default as payment_45methodzJ5AV6KWn0Meta } from "/home/runner/work/hub/hub/pages/client/account/plan/payment-method.vue?macro=true";
import { default as transactionsHMSFkBkVOmMeta } from "/home/runner/work/hub/hub/pages/client/account/plan/transactions.vue?macro=true";
import { default as plan7IRCIxBDqfMeta } from "/home/runner/work/hub/hub/pages/client/account/plan.vue?macro=true";
import { default as settingshdsjINFMiAMeta } from "/home/runner/work/hub/hub/pages/client/account/settings.vue?macro=true";
import { default as accountREMCTZMFkWMeta } from "/home/runner/work/hub/hub/pages/client/account.vue?macro=true";
import { default as bookingRyN8XftzB9Meta } from "/home/runner/work/hub/hub/pages/client/booking.vue?macro=true";
import { default as _91chatId_93BCCDcrw6R6Meta } from "/home/runner/work/hub/hub/pages/client/chat/[chatId].vue?macro=true";
import { default as chatyYsYPZA8uRMeta } from "/home/runner/work/hub/hub/pages/client/chat.vue?macro=true";
import { default as contact_45adminusFlUIZDxOMeta } from "/home/runner/work/hub/hub/pages/client/contact-admin.vue?macro=true";
import { default as editDjKcvkhjnpMeta } from "/home/runner/work/hub/hub/pages/client/journal/[journalId]/edit.vue?macro=true";
import { default as index0aWcp33c6NMeta } from "/home/runner/work/hub/hub/pages/client/journal/[journalId]/index.vue?macro=true";
import { default as _91journalId_93fu6JVuqqbaMeta } from "/home/runner/work/hub/hub/pages/client/journal/[journalId].vue?macro=true";
import { default as createVEGXjHvcUiMeta } from "/home/runner/work/hub/hub/pages/client/journal/create.vue?macro=true";
import { default as indexZqMvKjbB8cMeta } from "/home/runner/work/hub/hub/pages/client/journal/index.vue?macro=true";
import { default as packagesr56Wt0eRXdMeta } from "/home/runner/work/hub/hub/pages/client/packages.vue?macro=true";
import { default as _91sessionId_93E57ShgPiQiMeta } from "/home/runner/work/hub/hub/pages/client/session/[sessionId].vue?macro=true";
import { default as indexhLFnKGOEfFMeta } from "/home/runner/work/hub/hub/pages/client/session/index.vue?macro=true";
import { default as sessionqcqTTGlPRmMeta } from "/home/runner/work/hub/hub/pages/client/session.vue?macro=true";
import { default as profile2ZYu4Aeir5Meta } from "/home/runner/work/hub/hub/pages/client/therapist/profile.vue?macro=true";
import { default as client7AOkJRKHI8Meta } from "/home/runner/work/hub/hub/pages/client.vue?macro=true";
import { default as indexqACe91Vwe4Meta } from "/home/runner/work/hub/hub/pages/index.vue?macro=true";
import { default as insurance_45failedZhbNSIiIbOMeta } from "/home/runner/work/hub/hub/pages/insurance-failed.vue?macro=true";
import { default as insurance_45pendingpn4eqmKL8AMeta } from "/home/runner/work/hub/hub/pages/insurance-pending.vue?macro=true";
import { default as therapist_45recommendationsHQfUQsmxhZMeta } from "/home/runner/work/hub/hub/pages/therapist-recommendations.vue?macro=true";
import { default as help_45deskF03LSQ7fkRMeta } from "/home/runner/work/hub/hub/pages/therapist/account/help-desk.vue?macro=true";
import { default as aboutHFtyhS8kJxMeta } from "/home/runner/work/hub/hub/pages/therapist/account/profile/about.vue?macro=true";
import { default as arabic_45fieldKQ0MgqI8teMeta } from "/home/runner/work/hub/hub/pages/therapist/account/profile/arabic-field.vue?macro=true";
import { default as english_45fieldTWk0SDDqYZMeta } from "/home/runner/work/hub/hub/pages/therapist/account/profile/english-field.vue?macro=true";
import { default as profilewWZlAdLa9PMeta } from "/home/runner/work/hub/hub/pages/therapist/account/profile.vue?macro=true";
import { default as settingsZMPbZePSGHMeta } from "/home/runner/work/hub/hub/pages/therapist/account/settings.vue?macro=true";
import { default as accountnigh5dmJFcMeta } from "/home/runner/work/hub/hub/pages/therapist/account.vue?macro=true";
import { default as bookingTK3Os0dYaEMeta } from "/home/runner/work/hub/hub/pages/therapist/booking.vue?macro=true";
import { default as calendarMgMBrK2qk0Meta } from "/home/runner/work/hub/hub/pages/therapist/calendar.vue?macro=true";
import { default as _91chatId_93uT8w2qRdZDMeta } from "/home/runner/work/hub/hub/pages/therapist/chat/[chatId].vue?macro=true";
import { default as no_45messagesWD4gDrH71jMeta } from "/home/runner/work/hub/hub/pages/therapist/chat/no-messages.vue?macro=true";
import { default as chatP1P0CuYTGQMeta } from "/home/runner/work/hub/hub/pages/therapist/chat.vue?macro=true";
import { default as edit49nUoILJzYMeta } from "/home/runner/work/hub/hub/pages/therapist/client/[clientId]/clinical-files/[clinicalId]/edit.vue?macro=true";
import { default as indexqGCMAOlBuLMeta } from "/home/runner/work/hub/hub/pages/therapist/client/[clientId]/clinical-files/[clinicalId]/index.vue?macro=true";
import { default as _91clinicalId_93Cmdp7Pxqi2Meta } from "/home/runner/work/hub/hub/pages/therapist/client/[clientId]/clinical-files/[clinicalId].vue?macro=true";
import { default as create8er6F8kKaTMeta } from "/home/runner/work/hub/hub/pages/therapist/client/[clientId]/clinical-files/create.vue?macro=true";
import { default as index6nkMzKl9FMMeta } from "/home/runner/work/hub/hub/pages/therapist/client/[clientId]/clinical-files/index.vue?macro=true";
import { default as indexhZ4fnTVVsxMeta } from "/home/runner/work/hub/hub/pages/therapist/client/[clientId]/index.vue?macro=true";
import { default as _91journalId_939pohohPaBxMeta } from "/home/runner/work/hub/hub/pages/therapist/client/[clientId]/journals/[journalId].vue?macro=true";
import { default as indexXCBP4ZOb0ZMeta } from "/home/runner/work/hub/hub/pages/therapist/client/[clientId]/journals/index.vue?macro=true";
import { default as _91clientId_93Oyk4MI8mEtMeta } from "/home/runner/work/hub/hub/pages/therapist/client/[clientId].vue?macro=true";
import { default as indexQLtT54gsd0Meta } from "/home/runner/work/hub/hub/pages/therapist/clients/index.vue?macro=true";
import { default as contact_45adminIBfl4dNucgMeta } from "/home/runner/work/hub/hub/pages/therapist/contact-admin.vue?macro=true";
import { default as custom_45availabilityMudGdUFYvaMeta } from "/home/runner/work/hub/hub/pages/therapist/custom-availability.vue?macro=true";
import { default as schedule9LlcVl2NXyMeta } from "/home/runner/work/hub/hub/pages/therapist/schedule.vue?macro=true";
import { default as _91sessionId_93l0GoqUxMPNMeta } from "/home/runner/work/hub/hub/pages/therapist/session/[sessionId].vue?macro=true";
import { default as indexFnI2d43B4yMeta } from "/home/runner/work/hub/hub/pages/therapist/session/index.vue?macro=true";
import { default as sessionKUAIiGV3wlMeta } from "/home/runner/work/hub/hub/pages/therapist/session.vue?macro=true";
import { default as settingsYcZYIvhCf4Meta } from "/home/runner/work/hub/hub/pages/therapist/settings.vue?macro=true";
import { default as therapistdbszvrfbs7Meta } from "/home/runner/work/hub/hub/pages/therapist.vue?macro=true";
export default [
  {
    name: "auth-client-forget-password",
    path: "/auth/client/forget-password",
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-login",
    path: "/auth/client/login",
    meta: loginggWSM3H3jZMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-age-groups",
    path: "/auth/client/register/age-groups",
    meta: age_45groupskso8Ky8zd6Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/age-groups.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-client-gender",
    path: "/auth/client/register/client-gender",
    meta: client_45gender1ykDEi4QTfMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/client-gender.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-confirmedCompanyBenefit",
    path: "/auth/client/register/confirmedCompanyBenefit",
    meta: confirmedCompanyBenefitxHUj9fTHP3Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/confirmedCompanyBenefit.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-confirmedInsuranceBenefitCode",
    path: "/auth/client/register/confirmedInsuranceBenefitCode",
    meta: confirmedInsuranceBenefitCodeKRbUjiyju7Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/confirmedInsuranceBenefitCode.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-coverage",
    path: "/auth/client/register/coverage",
    meta: coveragewMAxJqpXZAMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/coverage.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-parent-verified",
    path: "/auth/client/register/parent-verified",
    meta: parent_45verifiedqn7xgixzNmMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/parent-verified.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-risk-assessment",
    path: "/auth/client/register/risk-assessment",
    meta: risk_45assessment64usT9rGT7Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/risk-assessment.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-therapist-preference",
    path: "/auth/client/register/therapist-preference",
    meta: therapist_45preference0XEyXyabrsMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/therapist-preference.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-therapy-reasons",
    path: "/auth/client/register/therapy-reasons",
    meta: therapy_45reasonsSPNaPaxssFMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/therapy-reasons.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-therapy-type",
    path: "/auth/client/register/therapy-type",
    meta: therapy_45typeodAKFpb7axMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/therapy-type.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-register-verify-email",
    path: "/auth/client/register/verify-email",
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/register/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-client-signup",
    path: "/auth/client/signup",
    meta: signupPyz1F1js3QMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/client/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexoq4ZR0Rdu5Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-therapist-activate-token",
    path: "/auth/therapist/activate/:token()",
    meta: _91token_93Fbd7TWsa1gMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/therapist/activate/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-therapist-forget-password",
    path: "/auth/therapist/forget-password",
    component: () => import("/home/runner/work/hub/hub/pages/auth/therapist/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-therapist-login",
    path: "/auth/therapist/login",
    meta: loginXWucLVgXm4Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/auth/therapist/login.vue").then(m => m.default || m)
  },
  {
    name: "client",
    path: "/client",
    meta: client7AOkJRKHI8Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/client.vue").then(m => m.default || m),
    children: [
  {
    name: "client-account",
    path: "account",
    meta: accountREMCTZMFkWMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/client/account.vue").then(m => m.default || m),
    children: [
  {
    name: "client-account-help-desk",
    path: "help-desk",
    component: () => import("/home/runner/work/hub/hub/pages/client/account/help-desk.vue").then(m => m.default || m)
  },
  {
    name: "client-account-overview",
    path: "overview",
    component: () => import("/home/runner/work/hub/hub/pages/client/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "client-account-plan",
    path: "plan",
    component: () => import("/home/runner/work/hub/hub/pages/client/account/plan.vue").then(m => m.default || m),
    children: [
  {
    name: "client-account-plan-coverages",
    path: "coverages",
    component: () => import("/home/runner/work/hub/hub/pages/client/account/plan/coverages.vue").then(m => m.default || m)
  },
  {
    name: "client-account-plan-overview",
    path: "overview",
    component: () => import("/home/runner/work/hub/hub/pages/client/account/plan/overview.vue").then(m => m.default || m)
  },
  {
    name: "client-account-plan-payment-method",
    path: "payment-method",
    component: () => import("/home/runner/work/hub/hub/pages/client/account/plan/payment-method.vue").then(m => m.default || m)
  },
  {
    name: "client-account-plan-transactions",
    path: "transactions",
    component: () => import("/home/runner/work/hub/hub/pages/client/account/plan/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "client-account-settings",
    path: "settings",
    component: () => import("/home/runner/work/hub/hub/pages/client/account/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "client-booking",
    path: "booking",
    component: () => import("/home/runner/work/hub/hub/pages/client/booking.vue").then(m => m.default || m)
  },
  {
    name: "client-chat",
    path: "chat",
    component: () => import("/home/runner/work/hub/hub/pages/client/chat.vue").then(m => m.default || m),
    children: [
  {
    name: "client-chat-chatId",
    path: ":chatId()",
    component: () => import("/home/runner/work/hub/hub/pages/client/chat/[chatId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "client-contact-admin",
    path: "contact-admin",
    component: () => import("/home/runner/work/hub/hub/pages/client/contact-admin.vue").then(m => m.default || m)
  },
  {
    name: _91journalId_93fu6JVuqqbaMeta?.name,
    path: "journal/:journalId()",
    component: () => import("/home/runner/work/hub/hub/pages/client/journal/[journalId].vue").then(m => m.default || m),
    children: [
  {
    name: "client-journal-journalId-edit",
    path: "edit",
    component: () => import("/home/runner/work/hub/hub/pages/client/journal/[journalId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "client-journal-journalId",
    path: "",
    component: () => import("/home/runner/work/hub/hub/pages/client/journal/[journalId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "client-journal-create",
    path: "journal/create",
    component: () => import("/home/runner/work/hub/hub/pages/client/journal/create.vue").then(m => m.default || m)
  },
  {
    name: "client-journal",
    path: "journal",
    component: () => import("/home/runner/work/hub/hub/pages/client/journal/index.vue").then(m => m.default || m)
  },
  {
    name: "client-packages",
    path: "packages",
    meta: packagesr56Wt0eRXdMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/client/packages.vue").then(m => m.default || m)
  },
  {
    name: sessionqcqTTGlPRmMeta?.name,
    path: "session",
    component: () => import("/home/runner/work/hub/hub/pages/client/session.vue").then(m => m.default || m),
    children: [
  {
    name: "client-session-sessionId",
    path: ":sessionId()",
    component: () => import("/home/runner/work/hub/hub/pages/client/session/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "client-session",
    path: "",
    component: () => import("/home/runner/work/hub/hub/pages/client/session/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "client-therapist-profile",
    path: "therapist/profile",
    meta: profile2ZYu4Aeir5Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/client/therapist/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/hub/hub/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "insurance-failed",
    path: "/insurance-failed",
    meta: insurance_45failedZhbNSIiIbOMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/insurance-failed.vue").then(m => m.default || m)
  },
  {
    name: "insurance-pending",
    path: "/insurance-pending",
    meta: insurance_45pendingpn4eqmKL8AMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/insurance-pending.vue").then(m => m.default || m)
  },
  {
    name: "therapist-recommendations",
    path: "/therapist-recommendations",
    meta: therapist_45recommendationsHQfUQsmxhZMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist-recommendations.vue").then(m => m.default || m)
  },
  {
    name: "therapist",
    path: "/therapist",
    meta: therapistdbszvrfbs7Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist.vue").then(m => m.default || m),
    children: [
  {
    name: "therapist-account",
    path: "account",
    meta: accountnigh5dmJFcMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist/account.vue").then(m => m.default || m),
    children: [
  {
    name: "therapist-account-help-desk",
    path: "help-desk",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/account/help-desk.vue").then(m => m.default || m)
  },
  {
    name: "therapist-account-profile",
    path: "profile",
    meta: profilewWZlAdLa9PMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist/account/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "therapist-account-profile-about",
    path: "about",
    meta: aboutHFtyhS8kJxMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist/account/profile/about.vue").then(m => m.default || m)
  },
  {
    name: "therapist-account-profile-arabic-field",
    path: "arabic-field",
    meta: arabic_45fieldKQ0MgqI8teMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist/account/profile/arabic-field.vue").then(m => m.default || m)
  },
  {
    name: "therapist-account-profile-english-field",
    path: "english-field",
    meta: english_45fieldTWk0SDDqYZMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist/account/profile/english-field.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "therapist-account-settings",
    path: "settings",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/account/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "therapist-booking",
    path: "booking",
    meta: bookingTK3Os0dYaEMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist/booking.vue").then(m => m.default || m)
  },
  {
    name: "therapist-calendar",
    path: "calendar",
    meta: calendarMgMBrK2qk0Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist/calendar.vue").then(m => m.default || m)
  },
  {
    name: "therapist-chat",
    path: "chat",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/chat.vue").then(m => m.default || m),
    children: [
  {
    name: "therapist-chat-chatId",
    path: ":chatId()",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/chat/[chatId].vue").then(m => m.default || m)
  },
  {
    name: "therapist-chat-no-messages",
    path: "no-messages",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/chat/no-messages.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91clientId_93Oyk4MI8mEtMeta?.name,
    path: "client/:clientId()",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/client/[clientId].vue").then(m => m.default || m),
    children: [
  {
    name: _91clinicalId_93Cmdp7Pxqi2Meta?.name,
    path: "clinical-files/:clinicalId()",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/client/[clientId]/clinical-files/[clinicalId].vue").then(m => m.default || m),
    children: [
  {
    name: "therapist-client-clientId-clinical-files-clinicalId-edit",
    path: "edit",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/client/[clientId]/clinical-files/[clinicalId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "therapist-client-clientId-clinical-files-clinicalId",
    path: "",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/client/[clientId]/clinical-files/[clinicalId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "therapist-client-clientId-clinical-files-create",
    path: "clinical-files/create",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/client/[clientId]/clinical-files/create.vue").then(m => m.default || m)
  },
  {
    name: "therapist-client-clientId-clinical-files",
    path: "clinical-files",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/client/[clientId]/clinical-files/index.vue").then(m => m.default || m)
  },
  {
    name: "therapist-client-clientId",
    path: "",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/client/[clientId]/index.vue").then(m => m.default || m)
  },
  {
    name: "therapist-client-clientId-journals-journalId",
    path: "journals/:journalId()",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/client/[clientId]/journals/[journalId].vue").then(m => m.default || m)
  },
  {
    name: "therapist-client-clientId-journals",
    path: "journals",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/client/[clientId]/journals/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "therapist-clients",
    path: "clients",
    meta: indexQLtT54gsd0Meta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "therapist-contact-admin",
    path: "contact-admin",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/contact-admin.vue").then(m => m.default || m)
  },
  {
    name: "therapist-custom-availability",
    path: "custom-availability",
    meta: custom_45availabilityMudGdUFYvaMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist/custom-availability.vue").then(m => m.default || m)
  },
  {
    name: "therapist-schedule",
    path: "schedule",
    meta: schedule9LlcVl2NXyMeta || {},
    component: () => import("/home/runner/work/hub/hub/pages/therapist/schedule.vue").then(m => m.default || m)
  },
  {
    name: sessionKUAIiGV3wlMeta?.name,
    path: "session",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/session.vue").then(m => m.default || m),
    children: [
  {
    name: "therapist-session-sessionId",
    path: ":sessionId()",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/session/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "therapist-session",
    path: "",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/session/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "therapist-settings",
    path: "settings",
    component: () => import("/home/runner/work/hub/hub/pages/therapist/settings.vue").then(m => m.default || m)
  }
]
  }
]