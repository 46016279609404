import type { UseFetchOptions } from '#app';
import { defu } from 'defu';
const { therapistToken, store, clear } = useAuthTherapistStorage();

export async function useTherapistApi<T>(
   url: string,
   options: UseFetchOptions<T> = {}
) {
   const config = useRuntimeConfig();
   const defaults: UseFetchOptions<T> = {
      baseURL: config.public.API_BASE_URL,
      key: url,
      headers:
         therapistToken?.value?.access_token ?
            { Authorization: `Bearer ${therapistToken?.value?.access_token}` }
         :  {},
      onResponse: async ({ response }) => {
         if (response.status == 401) {
            const { data, error } = await useFetch<{
               access_token: string;
               refresh_token: string;
            }>(`${config.public.API_BASE_URL}/refresh-token`, {
               method: 'POST',
               body: { refresh_token: therapistToken.value?.refresh_token },
               headers:
                  therapistToken?.value?.access_token ?
                     {
                        Authorization: `Bearer ${therapistToken?.value?.access_token}`,
                     }
                  :  {},
            });
            if (data.value) {
               store(data.value);
               setTimeout(() => {
                  useFetch(url, options as UseFetchOptions<T>);
               }, 500);
            }
            if (error.value) {
               clear();
               // window.location.href = '/auth/therapist/login';
            }
         }
      },
   };

   const params = defu(options, defaults);

   return useFetch(url, params);
}
