<template>
   <NuxtLayout>
      <div
         class="text-black h-full flex justify-center items-center overflow-hidden"
         style="min-height: 50dvh"
      >
         <div class="text-center flex flex-col items-center z-20 gap-10">
            <p class="font-medium" style="font-size: 8rem">
               {{ error?.statusCode }}
            </p>
            <p class="text-xl md:text-4xl md:w-2/3" style="font-weight: 200">
               {{ error.message }}
            </p>
            <BaseButton
               @click.prevent="goRedirect"
               color="white"
               class="border border-gray-25"
            >
               <p class="text-gray-200">Go back home</p>
            </BaseButton>
         </div>
      </div>
   </NuxtLayout>
</template>
<script setup>
   const { isTherapistAuthenticated } = useAuthTherapistStorage();
   const { isClientAuthenticated } = useAuthClientStorage();
   const error = useError();
   const router = useRouter();
   const goRedirect = () => {
      if (!isClientAuthenticated.value && !isTherapistAuthenticated.value) {
         return router.push('/auth/client/login');
      } else if (isClientAuthenticated.value) {
         return router.push('/client/booking');
      } else if (isTherapistAuthenticated.value) {
         return router.push('/therapist/booking');
      } else {
         return router.push('/');
      }
   };
</script>
<style></style>
