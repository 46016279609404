import type { UseFetchOptions } from '#app';
import { defu } from 'defu';
const { clientToken, store, clear } = useAuthClientStorage();

export async function useIFetch<T>(
   url: string,
   options: UseFetchOptions<T> = {}
) {
   const config = useRuntimeConfig();
   const defaults: UseFetchOptions<T> = {
      baseURL: config.public.API_BASE_URL,
      key: `${url}${
         options?.query ? JSON.stringify(options.query)
         : options?.params ? JSON.stringify(options.params)
         : ''
      }`,
      headers:
         clientToken?.value?.access_token ?
            { Authorization: `Bearer ${clientToken?.value?.access_token}` }
         :  {},
      onResponse: async ({ response }) => {
         if (response.status == 401) {
            const { data, error } = await useFetch<{
               access_token: string;
               refresh_token: string;
            }>(`${config.public.API_BASE_URL}/refresh-token`, {
               method: 'POST',
               body: { refresh_token: clientToken.value?.refresh_token },
               headers:
                  clientToken?.value?.access_token ?
                     {
                        Authorization: `Bearer ${clientToken?.value?.access_token}`,
                     }
                  :  {},
            });
            if (data.value) {
               store(data.value);
               setTimeout(() => {
                  useFetch(url, options as UseFetchOptions<T>);
               }, 500);
            }
            if (error.value) {
               clear();
               window.location.href = '/auth/client/login';
            }
         }
      },
   };
   const params = defu(options, defaults);

   return useFetch(url, params);
}
