import validate from "/home/runner/work/hub/hub/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45default_45global from "/home/runner/work/hub/hub/middleware/01.default.global.ts";
import manifest_45route_45rule from "/home/runner/work/hub/hub/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45default_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-client": () => import("/home/runner/work/hub/hub/middleware/auth-client.ts"),
  "auth-therapist": () => import("/home/runner/work/hub/hub/middleware/auth-therapist.ts"),
  client: () => import("/home/runner/work/hub/hub/middleware/client.ts"),
  register: () => import("/home/runner/work/hub/hub/middleware/register.ts"),
  "risk-assessment": () => import("/home/runner/work/hub/hub/middleware/risk-assessment.ts"),
  therapist: () => import("/home/runner/work/hub/hub/middleware/therapist.ts")
}