export interface IToken {
   access_token: string;
   refresh_token: string;
}
export const useAuthClientStorage = () => {
   const clientToken = useCookie<IToken | null>('clientToken');

   const isClientAuthenticated = computed(() =>
      clientToken?.value?.access_token ? true : false
   );

   const store = (newToken: IToken) => {
      clientToken.value = newToken;
   };

   const clear = () => {
      clientToken.value = null;
   };
   return { store, clear, clientToken, isClientAuthenticated };
};
