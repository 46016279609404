import { useIFetch } from '@/composables/useIFetch';
import apiRoutes from '@/const/apiRoutes';
import { type RegisterStep } from '~/composables/useRegisterSteps';
import type {
   AnonymousBodyInterface,
   AnonymousDataResponse,
} from '~/types/AnonymousDataResponse';
import type { ClientLoginInformation } from '~/types/ClientLoginInformation';
import type {
   ClientRegisterBodyInterface,
   ClientRegisterResponseInformation,
} from '~/types/ClientRegisterBodyInterface';
import type { CompanyVerifyBenefitCodeResponse } from '~/types/CompanyVerifyBenefitCodeResponse';
import type { InsuranceCoverageResponse } from '~/types/InsuranceCovergaeResponse';
import type { InsuranceVerifyBenefitCodeResponse } from '~/types/InsuranceVerifyBenefitCodeResponse';
const { isClientAuthenticated } = useAuthClientStorage();

export const useClientAuthStore = defineStore('clientAuth', () => {
   const { setUser } = useSentry();
   const { setSnackbarDetail } = useSnackbarStore();
   const { store, clear } = useAuthClientStorage();
   const { currentRegisterStep } = useRegisterSteps();
   const { clearAnonymous, storAnonymous, anonymousId } =
      useAnonymousClientStorage();
   const router = useRouter();
   const route = computed(() => useRoute());
   const { updateStatus } = useClientInfo();
   const anonymousData = ref<AnonymousDataResponse | null>(null);
   const companyBenefitSettings = ref<CompanyVerifyBenefitCodeResponse | null>(
      null
   );
   const coverageDetailBody = ref<InsuranceCoverageResponse | null>(null);
   const testingCode = ref<string | null>(null);
   const currentRegisterSteps: ComputedRef<RegisterStep[]> = computed(
      () => currentRegisterStep.value
   );
   const previousStep = ref(-1);
   const getNextStep = (skipStep = false) => {
      const currentStepIndex =
         currentRegisterSteps.value.findIndex(
            (step: RegisterStep) => step.to.name === route.value.name
         ) + (skipStep ? 1 : 0);
      previousStep.value = currentStepIndex;
      if (currentStepIndex === -1) return null;
      return currentRegisterSteps.value[currentStepIndex + 1] || null;
   };
   const getPreviousStep = () => {
      const currentStepIndex = currentRegisterSteps.value.findIndex(
         (step: RegisterStep) => step.to.name === route.value.name
      );
      if (currentStepIndex === -1) return null;
      if (currentStepIndex === 0) return { to: { name: 'auth-client-login' } };
      return currentRegisterSteps.value[currentStepIndex - 1] || null;
   };

   const SignUpAsClient = async (detail: ClientRegisterBodyInterface) => {
      let { data, error } = await useIFetch<ClientRegisterResponseInformation>(
         apiRoutes?.client?.auth?.signup,
         {
            method: 'POST',
            body: detail,
         }
      );
      if (data?.value) {
         setSnackbarDetail({
            show: true,
            title: data?.value?.message,
            type: 'message',
         });
         clearAnonymous();
         router.push(
            `/auth/client/register/verify-email?email=${detail?.email}`
         );
         anonymousData.value = null;
      }
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error?.value?.data?.message,
            type: 'error',
         });
      }
   };
   const loginAsClient = async (detail: {
      email: string;
      password: string;
   }) => {
      const { data, error } = await useIFetch<ClientLoginInformation>(
         apiRoutes?.client?.auth?.signin,
         {
            method: 'POST',
            body: detail,
         }
      );

      if (data?.value) {
         clearAnonymous();
         store(data?.value?.token);
         setSnackbarDetail({
            show: true,
            title: data?.value?.message,
            type: 'message',
         });
         setTimeout(() => {
            router.push('/client/booking');
         }, 500);
      }
      if (error?.value) {
         if (error?.value?.statusCode === 403) {
            await resendVerificationCode(detail.email);
            router.push(
               `/auth/client/register/verify-email?email=${detail.email}`
            );
         }
         setSnackbarDetail({
            show: true,
            title: error?.value?.data?.message,
            type: 'error',
         });
      }
   };
   const getAnonymousData = async () => {
      let { data, error } = await useIFetch<AnonymousDataResponse>(
         apiRoutes?.anonymous.get(anonymousId.value)
      );
      if (data?.value) {
         anonymousData.value = data?.value;
         updateStatus();
      }
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error?.value?.data?.message,
            type: 'error',
         });
      }
   };

   const createAnonymousId = async () => {
      let { data, error } = await useIFetch<{ id: string }>(
         apiRoutes?.anonymous.create,
         {
            method: 'POST',
         }
      );
      if (data?.value) {
         storAnonymous(data?.value?.id);
      }
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error?.value?.data?.message,
            type: 'error',
         });
      }
   };

   const updateAnonymousData = async (
      data: Partial<AnonymousBodyInterface>
   ) => {
      if (isClientAuthenticated?.value) {
         await useClientInfo().updateClientInfo(data);
      } else {
         let { error } = await useIFetch(
            apiRoutes?.anonymous?.update(anonymousId.value),
            {
               method: 'PUT',
               body: data,
            }
         );
         await getAnonymousData();
         if (error?.value) {
            setSnackbarDetail({
               show: true,
               title: error?.value?.data?.message,
               type: 'error',
            });
         }
      }
   };

   const verifyCompanyBenefitCode = async (body: {
      benefit_code: string;
      email: string;
   }) => {
      let { data, error } = await useIFetch<CompanyVerifyBenefitCodeResponse>(
         apiRoutes?.company?.verify,
         {
            method: 'POST',
            body: {
               ...body,
               anonymous_id: anonymousId.value,
            },
         }
      );
      if (data.value) {
         companyBenefitSettings.value = {
            ...data.value,
            benefit_code: body.benefit_code,
         };
         useSharedContentStore().therapyTypes = data?.value.therapy_types;
         return data.value;
      }
      if (error.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
         return error.value;
      }
   };
   const getCoverageDetail = async () => {
      let { data, error } = await useIFetch<InsuranceCoverageResponse>(
         apiRoutes?.client?.account?.coverage
      );

      if (data?.value) {
         coverageDetailBody.value = data?.value;
         if (data?.value?.coverage?.therapy_types?.length) {
            useSharedContentStore().therapyTypes =
               useSharedContentStore().therapyTypes?.filter((item: item) =>
                  data?.value?.coverage?.therapy_types?.includes(item.name)
               ) || [];
         }
         return data.value;
      }
      if (error.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
         return error.value;
      }
   };

   const verifyInsuranceBenefitCode = async (
      body: InsuranceVerifyBenefitCodeResponse
   ) => {
      let { data, error } = await useIFetch<CompanyVerifyBenefitCodeResponse>(
         apiRoutes?.insurance?.verify,
         {
            method: 'POST',
            body: {
               ...body,
               anonymous_id: anonymousId.value,
            },
         }
      );
      if (data.value) {
         return data.value;
      }
      if (error.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
         return error.value;
      }
   };
   const verifiedEmail = async (email: string, code: string) => {
      let { data, error } = await useIFetch(
         apiRoutes?.client?.auth.verifyToken,
         {
            method: 'POST',
            body: {
               action: 'activate_account',
               email: email,
               token: code,
            },
         }
      );
      if (data?.value) {
         setSnackbarDetail({
            show: true,
            title: (data.value as { message: string })?.message,
            type: 'message',
         });
         store((data?.value as { token: IToken })?.token);
         setTimeout(() => {
            router.push('/client/booking');
         }, 500);
      }
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
         return error.value;
      }
   };
   const resendVerificationCode = async (email: string) => {
      let { data, error } = await useIFetch(
         apiRoutes?.client?.auth.resendVerifyToken,
         {
            method: 'POST',
            body: {
               action: 'activate_account',
               email: email,
            },
         }
      );
      if (data.value) {
         setSnackbarDetail({
            show: true,
            title: (data.value as { message: string })?.message,
            type: 'message',
         });
         testingCode.value = (data.value as { token: string })?.token;
      }
      if (error.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
         return error.value;
      }
   };
   const logoutAsClient = () => {
      setUser(null);
      clear();
      location.reload();
   };
   const forgetPasswordAsClient = async (email: string) => {
      let { data, error } = await useIFetch(
         apiRoutes?.client?.auth.forgetPassword,
         {
            method: 'POST',
            body: {
               email,
            },
         }
      );
      if (data?.value)
         setSnackbarDetail({
            show: true,
            title: (data.value as { message: string })?.message,
            type: 'message',
         });
      if (error?.value)
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
      return { data, error };
   };
   const resetPasswordAsClient = async (dataObject: {
      email: string;
      new_password: string;
      confirm_password: string;
      token: string;
   }) => {
      let { data, error } = await useIFetch<{
         client_id: string;
         message: string;
         token: {
            access_token: string;
            refresh_token: string;
         };
      }>(apiRoutes?.client?.auth.resetPassword, {
         method: 'POST',
         body: dataObject,
      });
      if (data?.value) {
         setSnackbarDetail({
            show: true,
            title: (data.value as { message: string })?.message,
            type: 'message',
         });
         store(data.value.token);
         setTimeout(() => {
            router.push('/client/booking');
         }, 500);
      }
      if (error?.value)
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
      return { data, error };
   };
   const resendTokenAsClient = async (email: string, action: string) => {
      let { data, error } = await useIFetch<{ message: string }>(
         apiRoutes?.client?.auth.resendVerifyToken,
         {
            method: 'POST',
            body: {
               action,
               email,
            },
         }
      );
      if (data.value)
         setSnackbarDetail({
            show: true,
            title: data.value.message,
            type: 'message',
         });

      if (error.value)
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
      return { data, error };
   };
   return {
      loginAsClient,
      SignUpAsClient,
      getAnonymousData,
      anonymousData,
      anonymousId,
      logoutAsClient,
      createAnonymousId,
      updateAnonymousData,
      currentRegisterSteps,
      getNextStep,
      verifyCompanyBenefitCode,
      companyBenefitSettings,
      getPreviousStep,
      verifiedEmail,
      resendVerificationCode,
      testingCode,
      coverageDetailBody,
      getCoverageDetail,
      verifyInsuranceBenefitCode,
      forgetPasswordAsClient,
      resetPasswordAsClient,
      resendTokenAsClient,
      previousStep,
   };
});
