import { useIFetch } from '@/composables/useIFetch';
import apiRoutes from '@/const/apiRoutes';
export type item = {
   id: string;
   name: string;
   icon?: string | undefined;
   description?: string;
};

export enum AgeGroupEnum {
   below13 = 'Below 13',
   between13and17 = '13-17',
   above18 = 'Above 18',
   bellow18 = 'Below 18',
}
export const useSharedContentStore = defineStore('sharedContent', () => {
   const { setSnackbarDetail } = useSnackbarStore();
   const therapyReasons: Ref<item[] | null> = ref(null);
   const gender: Ref<item[] | null> = ref(null);
   const ageGroups: Ref<item[] | null> = ref(null);
   const therapyTypes: Ref<item[] | null> = ref(null);
   const languages: Ref<item[] | null> = ref(null);
   const countries: Ref<item[] | null> = ref(null);
   const insuranceCountries: Ref<item[] | null> = ref(null);
   const certificateList: Ref<item[] | null> = ref(null);
   const specialities: Ref<item[] | null> = ref(null);

   const getCertificates = async () => {
      let { data, error } = await useTherapistApi<item[] | null>(
         apiRoutes.content.certificates
      );
      if (data?.value) {
         certificateList.value = data?.value;
      }
      if (error?.value) {
         setSnackbarDetail({
            message: error?.value?.data?.message,
            type: 'error',
         });
      }
   };

   const getSpecialities = async () => {
      let { data, error } = await useTherapistApi<item[] | null>(
         apiRoutes.content.specialities
      );
      if (data?.value) {
         specialities.value = data?.value;
      }
      if (error?.value) {
         setSnackbarDetail({
            message: error?.value?.data?.message,
            type: 'error',
         });
      }
   };

   const getTherapyReasons = async (selectedTypeIds: string) => {
      try {
         const { data } = await useIFetch<{ data: item[] } | null>(
            apiRoutes.content.therapyReasons,
            {
               query: {
                  therapy_type: selectedTypeIds,
               },
            }
         );
         therapyReasons.value = data?.value?.data || [];
      } catch (e: any) {
         setSnackbarDetail({
            show: true,
            title: e?.message,
            type: 'error',
         });
      }
   };
   const getLanguages = async (fromTherapist?: Boolean) => {
      let endpoint = fromTherapist ? useTherapistApi : useIFetch;
      try {
         const { data } = await endpoint<item[] | null>(
            apiRoutes.content.languages
         );
         languages.value = data.value;
      } catch (e: any) {
         setSnackbarDetail({
            show: true,
            title: e?.message,
            type: 'error',
         });
      }
   };
   const getGender = async (fromTherapist?: Boolean) => {
      let endpoint = fromTherapist ? useTherapistApi : useIFetch;
      try {
         const { data } = await endpoint<item[] | null>(
            apiRoutes.content.gender
         );
         gender.value = data.value;
      } catch (e: any) {
         setSnackbarDetail({
            show: true,
            title: e?.message,
            type: 'error',
         });
      }
   };
   const getAgeGroups = async (query: { category: string }) => {
      try {
         const { data } = await useIFetch<item[] | null>(
            apiRoutes.content.ageGroups,
            { query }
         );
         ageGroups.value = data.value;
      } catch (e: any) {
         setSnackbarDetail({
            show: true,
            title: e?.message,
            type: 'error',
         });
      }
   };

   const getTherapyTypes = async () => {
      try {
         const { data } = await useIFetch<item[] | null>(
            apiRoutes.content.therapyTypes
         );
         therapyTypes.value = data.value;
      } catch (e: any) {
         setSnackbarDetail({
            show: true,
            title: e?.message,
            type: 'error',
         });
      }
   };
   const getCountries = async (fromTherapist: Boolean, filter = '') => {
      try {
         let endpoint = fromTherapist ? useTherapistApi : useIFetch;
         const { data } = await endpoint<item[] | null>(
            apiRoutes.content.countries + `?filter=${filter || ''}`
         );

         countries.value = data.value;
      } catch (e: any) {
         setSnackbarDetail({
            show: true,
            title: e?.message,
            type: 'error',
         });
      }
   };
   const getInsuranceCompanies = async (country_id: string) => {
      try {
         const { data } = await useIFetch<item[] | null>(
            apiRoutes.content.insurances,
            { query: { country_id } }
         );
         insuranceCountries.value = data.value;
         return data.value;
      } catch (e: any) {
         setSnackbarDetail({
            show: true,
            title: e?.message,
            type: 'error',
         });
      }
   };

   return {
      specialities,
      getSpecialities,
      certificateList,
      getCertificates,
      getTherapyReasons,
      getGender,
      getAgeGroups,
      therapyReasons,
      gender,
      therapyTypes,
      getTherapyTypes,
      ageGroups,
      languages,
      getLanguages,
      getCountries,
      countries,
      insuranceCountries,
      getInsuranceCompanies,
   };
});
