export const useSnackbarStore = defineStore('snackBar', () => {
   const snackbarDetail = ref({
      show: false,
   });

   const setSnackbarDetail = (data) => {
      snackbarDetail.value = data;
   };
   return { snackbarDetail, setSnackbarDetail };
});
