import dayjs from 'dayjs';
export default defineNuxtPlugin((nuxtApp) => {
   const getTimezoneDate = (timestamp) => {
      return dayjs
         .unix(timestamp)
         .tz(useTimeZoneStore().currentClientTimezone)
         .format('DD MMM, YYYY');
   };

   const getFormattedTime = (time, date) => {
      let currentDate = '';
      const locale = useCookie('i18n_redirected');

      if (date) {
         currentDate = dayjs(date).format('YYYY-MM-DD');
         return dayjs(currentDate + 'T' + time)
            .locale(locale.value ?? 'en')
            .format('hh:mm A');
      } else {
         currentDate = dayjs(new Date()).format('YYYY-MM-DD');
         return dayjs(currentDate + 'T' + time)
            .locale(locale.value ?? 'en')
            .format('hh:mm A');
      }
   };

   const formatCurrency = (number) => {
      let formatted = new Intl.NumberFormat('en-IN', {
         minimumFractionDigits: 0,
         maximumFractionDigits: 2,
      }).format(number);
      return formatted;
   };
   const useTimeFormat = (inputDate) => {
      if (!inputDate) {
         return '';
      }
      const locale = useCookie('i18n_redirected');

      return dayjs
         .utc(inputDate)
         .tz(useTimeZoneStore().currentClientTimezone)
         .locale(locale.value ?? 'en')
         .format('hh:mm A');
   };

   return {
      provide: {
         formatCurrency,
         getFormattedTime,
         getTimezoneDate,
         useTimeFormat,
      },
   };
});
