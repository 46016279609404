<template>
   <NuxtLoadingIndicator />
   <CommonSnackbar></CommonSnackbar>
   <Transition name="fade" mode="out-in">
      <div
         :key="locale"
         :class="locale === 'ar' ? 'rtl' : 'ltr'"
         class="w-full"
      >
         <div :class="isOnline ? '' : 'hidden'">
            <NuxtLayout />
         </div>
         <div
            :class="isOnline ? 'hidden' : ''"
            class="flex flex-col items-center justify-center py-10 gap-10"
         >
            <img :src="preloadedImage" />
            <div class="flex flex-col gap-2 text-center">
               <div class="text-xl font-bold">Unable to connect</div>
               <div class="text-base font-semibold text-gray-300">
                  Check your connection and reload the page
               </div>
            </div>
         </div>
      </div>
   </Transition>
</template>

<style>
   .rtl {
      direction: rtl;
   }

   .ltr {
      direction: ltr;
   }

   .fade-enter-active,
   .fade-leave-active {
      transition: opacity 0.2s ease;
   }

   .fade-enter-from,
   .fade-leave-to {
      opacity: 0.4;
   }
</style>
<script setup>
   const isOnline = ref(navigator.onLine);
   import preloadedImage from '~/public/image/no-connection.svg';
   const handleConnectionChange = () => {
      if (!navigator.onLine) isOnline.value = false;
      else isOnline.value = true;
   };

   onMounted(() => {
      window.addEventListener('online', handleConnectionChange);
      window.addEventListener('offline', handleConnectionChange);
   });

   onUnmounted(() => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
   });
   const { locale } = useI18n();
   const { setSnackbarDetail } = useSnackbarStore();
   useHead({
      titleTemplate: (titleChunk) => {
         return titleChunk ? `${titleChunk} | Talktime ae  ` : ' Talktime ae';
      },
   });
</script>
